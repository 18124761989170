import { createStore } from 'vuex'
import { getToken, removeToken } from '@/plugins/cookie'
import { Request } from '@/http/request'

export default createStore({
  state: {
    userType: '',
    user: {},
    roles: [],
    openList:[],
    token: getToken(),
    menus: [],
    permissions: [],
    tableHeight: '600',//表格高度
  },
  getters: {
    user: state => state.user || {},
    userType: state => state.userType,
    menus: state => state.menus,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER: (state, user) => {
        state.user = user
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus
    },
    SET_OPENLIST: (state, openList) => {
        state.openList = openList
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions
    },
    SET_USER_TYPE: (state, userType) => {
        state.userType = userType
    },
  },
  actions: {
    login ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    logout ({ commit }) {
      removeToken()
      commit('SET_USER_TYPE', '')
    },
    setUser ({ commit }, loginInfo) {
      commit('SET_USER', loginInfo)
    },
    setMenu ({ commit }, menus) {
      commit('SET_MENUS', menus)
    },
    setUserType ({ commit }, userType) {
      commit('SET_USER_TYPE', userType)
    },
    modifyUser({ commit }, userType) {
      console.log(userType)
      if (userType == 'system') {
        Request.axiosInstance.post('/system/admin-user/info').then((res) => {
          console.log(res)
          commit('SET_USER', res.data)
        })
      }
      // 注册用户重设
      if (userType == 'web') {
        Request.axiosInstance.post('/web/user/info').then((res) => {
            console.log(res)
            commit('SET_USER', res.data)
        })
      }
    },
  },
  modules: {
  }
})
