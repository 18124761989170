import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {message, notification} from 'ant-design-vue'
import {getToken, getTokenKey} from '@/plugins/cookie'
import QS from 'qs'
import router from '../router'
import store from '@/store'
import {ElNotification} from 'element-plus'

export class Request {
    public static axiosInstance: AxiosInstance

    public static init() {
        // 创建axios实例
        this.axiosInstance = axios.create({
            baseURL: /localhost|192.168/ig.test(location.hostname) ? '' : '/api',
            timeout: 15000
        })
        // 初始化拦截器
        this.initInterceptors()
        return axios
    }

    // 初始化拦截器
    public static initInterceptors() {
        // 设置post请求头
        this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'
        /**
         * 请求拦截器
         * 每次请求前，如果存在token则在请求头中携带token
         */
        this.axiosInstance.interceptors.request.use(
            (config: any) => {
                // if (config.url.indexOf('/upload') == -1) { 
                //     config.headers['Content-Type'] = 'multipart/form-data' 
                // }
                // const token = Vue.ls.get(ACCESS_TOKEN)
                // if (token) {
                //     config.headers['Authorization'] = 'Bearer ' + token
                // }

                // showError控制是否展示错误信息，具体在 Request.errorHandle方法中处理
                let showError = config.data?.showError;
                if (showError == null) {
                    showError = true;
                }
                config.showError = showError;
                if (config.data?.showError != null) {
                    delete config.data.showError;
                }

                // 登录流程控制中，根据本地是否存在token判断用户的登录情况
                // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
                // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
                if (config.headers['Content-Type'] == 'application/json') {
                    config.data = QS.parse(QS.stringify(config.data, {skipNulls: true}))
                }
                // config.data = QS.parse(QS.stringify(config.data, { skipNulls: true }))
                if (getToken()) {
                    config.headers[getTokenKey()] = getToken()
                }
                return config
            },
            (error: any) => {
                console.log(error)
            },
        )


        // 响应拦截器
        this.axiosInstance.interceptors.response.use(
            // 请求成功
            (response: AxiosResponse) => {
                // if (res.headers.authorization) {
                //     localStorage.setItem('id_token', res.headers.authorization)
                // } else {
                //     if (res.data && res.data.token) {
                //         localStorage.setItem('id_token', res.data.token)
                //     }
                // }

                // if (response.status === 200) {
                //     // return Promise.resolve(response.data)
                //     return response
                // } else {
                //     Request.errorHandle(response)
                //     // return Promise.reject(response.data)
                //     return response
                // }

                if (response.data.code && (response.data.code == '200' || response.data.code == '204')) {
                    // 如果code是200, 操作成功直接返回
                    return response.data
                } else if (response.data.code && response.data.code == '401') {
                    // message.info('请先登录')
                    store.dispatch('logout').then(() => router.push("/login"))
                } else if (!response.data.code && (response.data.status == '200' || response.status == 200)) {
                    // 如果没有code,但status是200,表示后端不是通过自定义的response返回,例如文件下载
                    return response.data
                } else if (response.data.code == '203') {
                    return response.data
                } else {
                    Request.errorHandle(response)
                    return Promise.reject(response)
                }

            },
            // 请求失败
            (error: any) => {
                const {response} = error
                if (response) {
                    // 请求已发出，但是不在2xx的范围
                    Request.errorHandle(response)
                    return Promise.reject(response.data)
                } else {
                    // 处理断网的情况
                    // eg:请求超时或断网时，更新state的network状态
                    // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
                    // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
                    // message.warn('网络连接异常,请稍后再试!')
                    // Request.showNotification('网络异常', '网络连接异常,请稍后再试!');
                    // ElNotification({
                    //     title: '网络异常',
                    //     message: '网络连接异常,请稍后再试!',
                    // });
                    return Promise.reject(response)
                }
            })
    }


    /**
     * http握手错误
     * @param res 响应回调,根据不同响应进行不同操作
     */
    private static errorHandle(response: any) {
        // console.error('errorHandle', response)
        // 状态码判断
        switch (response.status) {
            case 401:
                break
            case 403:
                break
            case 404:
                // message.error('请求的资源不存在')
                if (response?.config?.showError) {
                    Request.showNotification('警告', '请求的资源不存在');
                }
                break
            default:
                if (response?.config?.showError) {
                    // message.error(response.data.message || '网络异常')
                    Request.showNotification('警告', response.data.message || '网络异常');
                }
        }
    }

    private static lastNotifMsg: string;//上次notify内容
    private static lastNotifMills: number;//上次notify时间

    private static showNotification(title?: string, msg?: string) {
        if (!msg) {
            return;
        }
        // console.log('showNotification', title, msg)
        // if (Request.lastNotifMsg == msg && (new Date().getTime() - Request.lastNotifMills  < 2 * 1000)) {
        //     console.log('showNotification', title, msg, Request.lastNotifMills, Request.lastNotifMills - new Date().getTime())
        //     return;
        // }
        Request.lastNotifMsg = msg;
        Request.lastNotifMills = new Date().getTime();
        ElNotification({
            title: title,
            message: msg,
            type: 'warning',
            duration:3000
        });
    }
}