// 工具类方法

// 获取所有上级
export function getParent(data2, nodeId2) {
    var arrRes = [];
    if (data2.length == 0) {
        if (nodeId2) {
            arrRes.unshift(data2)
        }
        return arrRes;
    }
    let rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
            let node = data[i];
            if (node.id == nodeId) {
                arrRes.unshift(node)
                rev(data2, node.parent_id);
                break;
            }
            else {
                if (node.children) {
                    rev(node.children, nodeId);
                }
            }
        }
        return arrRes;
    };
    arrRes = rev(data2, nodeId2);
    return arrRes;
}

// 字符串=>数组 (,为间隔)
export function stringToArr(string){
    return string.split(',').filter(x => x != ''&&x != '-1')
}

// 根据route属性获取树形集合的所有上级节点
export function getParentByPath(list, path) {
    for (let i in list) {
        if(list[i].route == path){
            return [list[i]]
        }
        if(list[i].children){
            let node = getParentByPath(list[i].children, path)
            if(node !== undefined){
                return node.concat(list[i])
            }
        }
    }        
}

// list => 属性结构集合
// compareFunc => 比较方法
export function getParents(list, compareFunc) {
    for (let i in list) {
        if(compareFunc(list[i])){
            return [list[i]]
        }
        if(list[i].children){
            let node = getParents(list[i].children, compareFunc)
            if(node !== undefined){
                return node.concat(list[i])
            }
        }
    }        
}


// 树形扁平化,支持任意深度
export function flatten(arr) {
    var res = []
    arr.map(item => {
        if(Array.isArray(item.children) && item.children.length > 0) {
            res.push(item)
            res = res.concat(flatten(item.children))
        } else {
            res.push(item)
        }
    })
    return res
}