import Cookies from 'js-cookie'

const TokenKey = ''

export function getToken() {
  let token = sessionStorage.getItem(TokenKey);
  if (token) {
   return token
  } 
  return  Cookies.get(TokenKey)
  
}
export function getTokenKey() {
  return TokenKey
}
export function setToken(token:any) {
  return Cookies.set(TokenKey, token, { expires: 3 });
}

export function setSessionToken(token:any) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  sessionStorage.removeItem(TokenKey)
  return Cookies.remove(TokenKey)
}