import { createApp } from 'vue'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import '@/assets/css/element-variables.scss'
import App from './App.vue'
import Vue from 'vue'
import router from './router'
import installElementPlus from './plugins/element'
import store from './store'
import '@mdi/font/css/materialdesignicons.min.css'
import '@/assets/css/mediaSet.css'
import '@/assets/css/default.scss'
import '@/assets/material3/common/style.m3.css'
import '@/assets/css/style.business.scss'
import '@/assets/iconfont/iconfont.css'
// // 设置eleui为中文
// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale'
// locale.use(lang)

const app = createApp(App)
installElementPlus(app)
// app.use(ElementPlus)
app.use(store).use(router).mount('#app')

// axios
import { Request } from '@/http/request'
import VueAxios from 'vue-axios'
app.use(VueAxios, Request.init())

// antd
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/antd.less'
app.use(Antd)

// 极验
import gt from '@/public/js/gt.js'
app.use(gt)

// import p5 from '@/public/js/p5.min.js'
// app.use(p5)

import d3 from '@/public/js/d3-delaunay.min.js'
app.use(d3)

// 过滤器
import filters from '@/utils/filter'
app.config.globalProperties.$filter = filters

// 单独引入eleui MessageBox 弹框
import { ElMessageBox } from 'element-plus';


app.config.globalProperties.$confirm=ElMessageBox.confirm;