// 全局过滤器
const vFilter = {
    // appId => 模块匹配
    appIdToModule(appId, moduleMap) {
        return moduleMap[appId]
    },
    // 匹配出关键字
    mateKeyword(sentence, keyword) {
        keyword?.forEach(item => {
            if (sentence?.indexOf(item) != -1) {
                // sentence = sentence.replace(item,'<span style="color:#0058FF;">'+item+'</span>')
                sentence = sentence.replace(item, '<b class="keyword-tag" style="color:#ff0000;">' + item + '</b>')
            }
        });
        return sentence
    },
    // 匹配判断绑定状态
    bindingState(status, expireTime) { //status 0 已绑定 0&&当前时间>=到期时间 自动解绑 1 手动解绑 2自动解绑
        if (status == 0) {
            let nowTime = Date.parse(new Date())
            let newExpire = new Date(expireTime)
            let newExpireTime = Date.parse(newExpire);
            if (nowTime >= newExpireTime) {
                return '自动解绑'
            } else {
                return '已绑定'
            }
        }
        if (status == 1) {
            return '手动解绑'
        }
        if (status == 2) {
            return '自动解绑'
        }
    },
    // 匹配判断绑定状态
    bindingClass(status, expireTime) { //status 0 已绑定 0&&当前时间>=到期时间 自动解绑 1 手动解绑 2自动解绑
        if (status == 0) {
            let nowTime = Date.parse(new Date())
            let newExpire = new Date(expireTime)
            let newExpireTime = Date.parse(newExpire);
            if (nowTime >= newExpireTime) {
                return 'no-red'
            } else {
                return 'yes-green'
            }
        }
        if (status == 1) {
            return 'no-red'
        }
        if (status == 2) {
            return 'no-red'
        }
    },
}

export default vFilter

// 验证多ip逗号分隔和单ip
export function isValidIp(arg) {
    var regEx = /,/g
    var ipList = arg.toString().replace(regEx, ',').split(',')
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    var pattern = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\/(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    for (var i in ipList) {
        if (!reg.test(ipList[i]) && !pattern.test(ipList[i])) {
            return false
        } else {
            callback()
        }
    }
}

