<template>
  <!--  element组件设置中文  -->
  <el-config-provider :locale="locale" v-if="isRouterAlive">
    <!--    &lt;!&ndash;  ant-design组件设置中文  &ndash;&gt;-->
    <!--    <a-config-provider :locale="zhCN">-->
    <router-view/>
    <!--    </a-config-provider>-->
  </el-config-provider>
</template>

<script>
import {defineComponent, ref, nextTick, provide} from 'vue'
import {ElConfigProvider} from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
// import {AConfigProvider} from "ant-design-vue/lib/config-provider";

export default defineComponent({
  components: {
    // AConfigProvider,
    ElConfigProvider,
  },
  setup() {
    // 局部组件刷新
    const isRouterAlive = ref(true)
    const reload = () => {
      isRouterAlive.value = false
      nextTick(() => {
        isRouterAlive.value = true
      });
    };
    provide("reload", reload)
    return {
      locale: ref(zhCn), zhCN,
      isRouterAlive,
    }
  }

})
</script>

<style lang="scss">
.flex {
  display: flex;
  align-items: center;
}

.text-flow {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@font-face {
  font-family: PingFang-Bold;
  src: url('./assets/font/PingFang Bold_0.ttf');
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: PingFang-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

// *::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }
// ::-webkit-scrollbar {
//   width: 22px;
//   height: 5px;
// }
*::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-thumb {
  height: 0;
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 10px;
  background-color: rgba(99, 125, 255, 0.3);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .1), inset 1px 1px 0 rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-thumb:hover {
  border: 5px solid rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

// eleui 弹窗定制样式
.el-overlay .el-dialog {
  border-radius: 16px;

  .el-dialog__header {
    background: #F2F2F7;
    padding: 16px;
    border-radius: 16px 16px 0 0;

    .el-dialog__title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }
  }
}

.center-body {
  .el-form-item {
    margin-bottom: 0;

    .el-form-item__label {
      color: #8B96CC;
      padding: 0;
    }
  }
}

.elCascader {
  .el-cascader {
    display: block;
  }
}

.el-collapse-item__header {
  font-size: .9375rem;
  font-weight: 600;
  color: #303133;
}

.jobs {
  .el-page-header .el-page-header__content {
    font-size: 14px;
    color: #303133;
    cursor: pointer;
  }
}

//
.the-drawer .ant-tabs-nav-wrap {
  text-align: center !important;
}

.the-drawer .ant-tabs-tab {
  margin-right: 4.375rem !important;
  padding: 1.125rem 1.125rem !important;
}

.the-drawer .ant-tabs-tab:last-child {
  margin-right: 0 !important;
}

.the-drawer .ant-tabs-nav-scroll {
  background: #E8ECFF;
}

// .user-operation .ant-tabs-nav-wrap{
//   text-align: center !important;
// }
// .user-operation .ant-tabs-tab{
//   margin-right: 4.375rem !important;
//   padding: 1.125rem 1.125rem !important;
// }
// .user-operation .ant-tabs-tab:last-child {
//     margin-right: 0 !important;
// }
// .user-operation .ant-tabs-nav-scroll{
//   background: #E8ECFF;
// }

.el-table__body-wrapper {
  overflow-y: overlay !important;
  // height: calc(100vh - 400px) !important;
}

.el-form-item {
  margin-bottom: 22px !important;
}

.el-card {
  border-radius: .5rem;
  border: 0;
  /* border: 1px solid var(--el-card-border-color); */
  /* background-color: var(--el-card-background-color); */
  /* overflow: hidden; */
  /* color: var(--el-text-color-primary); */
  /* transition: var(--el-transition-duration); */
}

.el-card__body {
  padding: 0;
}

// 隐私号公用css
.pager {
  display: flex;
  justify-content: flex-end;
}

.content {
  height: 100%;
  overflow-x: overlay;
  overflow-y: overlay;
  padding-right: 18px;

  .title-name {
    // margin-bottom: 1rem;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }

  .title-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    // background-color:orange;
  }

  .screening {
    margin-bottom: 0.9375rem;

    .screening-out {
      margin: 0.9375rem;
    }

    .title-button {
      padding-right: 0;
    }
  }
}

.el-row {
  margin-bottom: .9375rem;

  &:last-child {
    margin-bottom: 0;
  }
}

</style>
